<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-lg-0 pt-2 form"
      >
        <b-col sm="8" md="10" lg="10" class="mx-auto mt-lg-5 paid_consulting">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Paid Consulting Assignment
          </b-card-title>
          <hr />

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- currency -->
              <b-form-group label="Currenncy" label-for="currency">
                <validation-provider
                  #default="{ errors }"
                  name="Currency"
                  rules="required"
                >
                  <b-form-select
                    v-model="form.current_currency"
                    :options="currencyOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- desired-charge -->
              <b-form-group
                label="Desired Charge per Hour"
                label-for="desired-salary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Desired Annual Salary"
                  rules="required"
                >
                  <b-form-input
                    id="desired-salary"
                    v-model="form.charge_per_hour"
                    type="number"
                    name="desired-salary"
                    :state="errors.length > 0 ? false : null"
                    placeholder="50000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group label="Choose Your Expertise" label-for="expertise">
                <validation-provider
                  #default="{ errors }"
                  name="Preferred Location"
                  rules="required"
                >
                  <v-select
                    v-model="form.expertise"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="name"
                    :options="expertises"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- <p class="text-primary m-0">
                <u style="cursor: pointer" @click="toFullTime"> I also want to register as an Executive</u>
              </p> -->
              <b-button
                variant="flat-primary"
                type="submit"
                @click.prevent="toFullTime"
              >
                I also want to register as an Executive
              </b-button>

              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  class="my-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Next
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { required } from "@validations"
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from "vue-select"

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        current_currency: null,
        charge_per_hour: null,
        expertise: [],
      },

      isFullTime: false,

      dir: "ltr",

      currencyOptions: [
        { value: "usd", text: "US Doller(USD)" },
        { value: "inr", text: "Indian Rupee(INR)" },
        { value: "cny", text: "China Yuan(CNY)" },
      ],

      expertises: [],

      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // setTimeout(() => {
    //   axios.all([this.fetchExpertise()]);
    // }, 500);
    this.fetchExpertise()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.postForm()
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Form Submitted',
          //     icon: 'EditIcon',
          //     variant: 'success',
          //   },
          // });
        }
      })
    },
    postForm() {
      store.dispatch("auth/paidConsultingForm", this.form).then((response) => {
        if (response.data.status === "success") {
          if (this.isFullTime) {
            this.$router.push({ name: "fulltime-form" })
          } else {
            this.$router.push({ name: "set-profile-picture" })
            this.isFullTime = false
          }
        }
      })
    },
    fetchExpertise() {
      store.dispatch("auth/getExpertises").then((response) => {
        if (response.status === 200) {
          this.expertises = store.state.auth.expertises
        }
      })
    },
    toFullTime() {
      this.isFullTime = true
      this.validationForm()
      // store.dispatch('auth/paidConsultingForm', this.form).then((response) => {
      //   if (response.data.status === 'success') {
      //     this.$router.push({ name: 'fulltime-form' });
      //   }
      // });
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

@media only screen and (max-width: 950px) {
  .form {
    margin-top: -280px;
  }
  .paid_consulting {
    margin-top: -150px;
  }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
